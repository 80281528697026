import React from 'react'

const Testimonial = () => {
    return (
        <div className="container-fluid testimonial  py-5">
            <div>
                <p className="title">What Our Clients Says</p>
                <br/>
                <br/>
                <br/>
            </div>
            <div className="row container">
                <div className="col-md-6 d-flex align-items-center">
                    <p className="bggm my-auto d-md-block">WHY BGGM VETERINARY IS TRUSTED BY PET OWNERS</p>
                </div>
                <div className="col-md-6">
                <div id="demo" class="carousel slide d-flex flex-row justify-content-between align-items-center" data-ride="carousel">

                    <a href="#demo" data-slide="prev">
                    <span className="font-weight-bold px-md-5 carousel-control-prev-icon"  style={{fontSize : "30px"}}>
                        {"<"}
                    </span>
                    </a>
                <div class="carousel-inner">
                    <div class="carousel-item text-left p-4 active">
                        <h3  className="py-3 font-weight-bold">Rock Alexander</h3>
                        <p className="py-2"> <i class="fas fa-star text-warning"></i> <i class="fas fa-star text-warning"></i> <i class="fas fa-star text-warning"></i> <i class="fas fa-star text-warning"></i> <i class="fas fa-star text-warning"></i></p>
                        <p className="py-4 font-weight-italic">They have always beem so accessible and willing to work with whatever needs that i had at the time.</p>
                    </div>
                    <div class="carousel-item text-left p-4">
                        <h3  className="py-3 font-weight-bold">Carol Anderson</h3>
                        <p className="py-2"> <i class="fas fa-star text-warning"></i> <i class="fas fa-star text-warning"></i> <i class="fas fa-star text-warning"></i> <i class="fas fa-star text-warning"></i> <i class="fas fa-star"></i></p>
                        <p className="py-4 font-weight-italic">"I find that all involved in our pets care are trustworthy and caring individuals. The follow-up call from Dr. Lee was much appreciated. "</p>
                    </div>
                    <div class="carousel-item text-left p-4">
                        <h3  className="py-3 font-weight-bold">Willy Rockwell</h3>
                        <p className="py-2"> <i class="fas fa-star text-warning"></i> <i class="fas fa-star text-warning"></i> <i class="fas fa-star text-warning"></i> <i class="fas fa-star text-warning"></i> <i class="fas fa-star text-warning"></i></p>
                        <p className="py-4 font-weight-italic">"I’m very thankful for everyone’s help in the facility, Timi is getting better every day and that’s because of your service !! Thank you for everything "</p>
                    </div>
                </div>
                <a href="#demo" data-slide="prev">
                    <span className="font-weight-bold   px-md-4 carousel-control-next-icon"  style={{fontSize : "30px"}}>
                        {">"}
                    </span>
                    </a>
                </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonial
