import React from 'react'

const LandingPage = () => {
    return (
        <div className="container-fluid landing-page">
            <div className="container py-5">
                <div className="row py-5">
                <div className="col-md-8  text-left">
                <div>
                <span className="head1">TAKING CARE OF </span> 
                <span className="head2"> ANIMALS AS IF THEY </span>
                <span className="head3"> WERE OUR OWN </span>  
                <span className="head4">TAKING CARE OF ANIMALS AS IF THEY WERE OUR OWN</span>
                </div>
                <div className="mr-md-5 pr-md-5 my-5">
                    <p  className="mr-md-5 pr-md-5 my-5">BGGM VETERINARY works to be the most trusted and respected pet care provider in our community by administering the best possible medical care to every patient.</p>
                </div>
                <div className="my-3">
                    <a href="mailto:info@bggm.co.uk">
                    <button className="btn home-btn btn-outline-info p-2 px-4">Contact Us </button>
                    </a>
                </div>
                </div>
                <div className="col-md-4">
                    <img src="/img/l.png" className="img-fluid d-none d-md-block"/>
                </div>
            </div>
            </div>
           
        </div>
    )
}

export default LandingPage
