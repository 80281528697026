import React from 'react'

const Aside = () => {
    return (
        <div className="container-fluid aside">
            <div className="row">
                <div className="col-md-6">
                </div>
                <div className="col-md-6">
                    <p className="aside-text">“Any glimpse into the life of an animal quickens our own and makes it so much larger and better in every way.” </p>
                    <p className="aside-alt">John Muir, Scottish-American Naturalist</p>
                </div>
            </div>
        </div>
    )
}

export default Aside
