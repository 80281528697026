import { useEffect } from 'react';
import './App.css';
import About from './components/about';
import Aside from './components/aside';
import { Contact } from './components/footer';
import LandingPage from './components/landingPage';
import Navigation from './components/navbar';
import Services from './components/services';
import Testimonial from './components/testimonial';

function App() {
  return (
  <div className="App">
      <Navigation/>
      <LandingPage/>
      <About/>
      <Services/>
      <Aside/>
      <Testimonial/>
      <Contact/>
    </div>
  );
}

export default App;
