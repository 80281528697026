import React from 'react'

const Services = () => {
    return (
        <>
        <div className="container py-5">
            <div>
                <p className="title">WHAT WE DO</p>
                <br/>
                <br/>
                <br/>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <div className="service-container">
                        <img src="/img/pk.jpg" className="service-img"/>
                        <div className="px-5">
                            <h3 className="my-5 text-muted">PUPPY AND KITTEN CARE</h3>

                            <p className="my-5">Our dedicated, highly trained and experienced veterinary surgeons are able to offer a wide range of advanced surgery for your pet, should the need arise.</p>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-4 ">
                    <div className="service-container">
                        <img src="/img/v.jpg" className="service-img"/>
                        <div className="px-5">
                            <h3 className="my-5 text-muted">VACCINATION</h3>

                            <p className="my-5">Vaccinating your cat, dog, puppy or kitten is one of the most important things that you can do as a responsible and caring owner. It will help your pet to live a long and healthy life.</p>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-4 ">
                    <div className="service-container">
                        <img src="/img/p.jpg" className="service-img"/>
                        <div className="px-5">
                            <h3 className="my-5 text-muted">Pet Passports and
                                Travel</h3>
                            <p className="my-5"> It’s vital that you are fully aware of the new requirements and are organised well in advance to avoid any problems when travelling and to ensure the process is as smooth and hassle-free as possible.</p>
                        </div>
                    </div>
                    </div>
                </div>
        </div>
        <div className="container-fluid feature">
            <div className="row py-5">
                <div className="col-md-4 my-5">
                <i className="fas feature-img fa-headset"></i>
                    <p>GOOD CUSTOMER SERVICE</p>
                </div>
                <div className="col-md-4 my-5 ">
                <i className="far feature-img fa-calendar-minus"></i>
                    <p>ESTABLISHED 2010</p>
                </div>
                <div className="col-md-4 my-5">
                <i className="fas feature-img fa-shield-alt"></i>                    <p>TRUSTED COMPANY</p>
                </div>
            </div>
        </div>
        </>
    )
}

export default Services
