 const About = (props) => {
    return (
      <div id='about'>
        <div className='container pt-5'>
          <div className='row'>
            <div className='col-xs-12 col-md-6 d-flex align-items-end'>
              {' '}
              <img src='img/ll.jpg' className='img-fluid' alt='' />{' '}
            </div>
            <div className='col-xs-12 col-md-6'>
              <div className='about-text'>
                <h2 className="title">About Us</h2>
                <p>BGGM VETERINARY has been committed to providing advanced, high-quality medical, surgical and dental care for small animals. We know that the key to optimal health is prevention – and your pet’s wellness plan should be designed based on your pet’s unique lifestyle, environment, breed and medical history. </p>
                <div className='list-style text-left'>
                  <div className='col-lg-6 col-sm-6 col-xs-12'>
                    <ul>
                            <li>Small animal services</li>
                            <li>Preventive care</li>
                            <li>Advanced dentistry</li>
                            <li>Laboratory diagnostics</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  export default About