const Navigation = () => {
  return (
      <div className="bg-dark">
        <nav class="navbar px-5 fixed-top bg-dark navbar-expand-lg  navbar-dark py-3">
        <a to="#"><img src="/img/logo.png" class="navbar-brand" height="50"/></a>
        <button class="navbar-toggler border-0" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon text-light"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mx-auto">
            <li class="nav-item">
                <a class="nav-link  mx-2" href="#">Home <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link  mx-2" href="#about">About Us</a>
            </li>
            <li class="nav-item">
                <a class="nav-link  mx-2" href="#contact">Contact Us</a>
            </li>
            </ul>
            <a href="tel:+44 (0) 1215729718">
            <button style={{borderRadius : "100px", backgroundColor : "white"}} className="ml-auto border-0 py-2 px-4 ml-3 shadow text-dark font-weight-bold"><i className="fas fa-phone"></i> Place A Call</button>
            </a>
        </div>
        </nav>
 </div>

    )
}
export default Navigation